.page-enter {
    opacity: 0;
}
.page-enter-active {
    opacity: 1;
    transition: opacity 1s;
}
.page-exit {
    opacity: 1;
}
.page-exit-active {
    opacity: 0;
    transition: opacity 1s;
}

.MuiPopper-root.MuiDataGrid-panel .MuiDataGrid-columnsPanel .MuiFormControlLabel-root .MuiTypography-root {
    text-transform: capitalize;
}

html,
body {
    height: 100%;
}
