@-webkit-keyframes blink-animation {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blink-animation {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.blink {
    -webkit-animation-name: blink-animation;
    animation-name: blink-animation;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
